// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

// $primary: #0074d9;
// $danger: #ff4136;

// $theme-colors: (
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// );

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

.map-position {
  background-color: rgba(43, 48, 53, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  font-size: 16px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  border-radius: 4px;
  line-height: 24px;
  height: 38px;
}
